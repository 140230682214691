import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import {
  Alert,
  Checkbox,
  Icon,
  Input,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../Common/Footer";
import { Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import CreditRecommendationReport from "./CreditRecommendationReport";
import { callAPI, controllerAPI } from "../API/FP/Actions";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import {
  contextforrejectingOffer,
  contextObjForPlacingOffer,
} from "../Common/Constants";
import { Tooltip } from "@mui/material";
import { Toll } from "@mui/icons-material";

function FinanceRequestIndividual({
  data,
  actionDateFunction,
  creditScoreColor,
  creditScoreReportCheck,
  setCreditScoreReportCheck,
  getCreditScore,
  authToken,
}) {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [interestPercentage, setInterestPercentage] = useState(
    data?.requestOffer?.interestPtg ?? 0
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [showOtherField, setShowOtherField] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const reasons = [
    "Did not like the Anchor Trader",
    "The requested amount is not within the permissible limits",
    "The interest rate is high",
    "The credit score is low",
  ];
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [editClickAdjust, setEditClickAdjust] = useState(false);
  const [editClickAdjustMargin, setEditClickAdjustMargin] = useState(false);

  const [editClickAdjustMarginvalue, setEditClickAdjustMarginvalue] =
    useState(false);
  const [editClickAdjustAmountAftMargin, setEditClickAdjustAmountAftMargin] =
    useState(false);
  const [editClickAdjustIntrestRate, setEditClickAdjustIntrestRate] =
    useState(false);
  const [editClickAdjustIntrestValue, setEditClickAdjustIntrestValue] =
    useState(false);
  const [editamntToBeDisbursed, seteditamntToBeDisbursed] = useState(false);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const [isAdjustingIntrestVal, setIsAdjustingIntrestVal] = useState(false);
  const [interestValue, setInterestValue] = useState(
    data?.requestOffer?.interestValue ?? 0
  );
  const handleSelectReason = (reason) => {
    if (reason === "Other") {
      setShowOtherField(true);
      setSelectedReason("Other");
    } else {
      setSelectedReason(reason);
      setShowOtherField(false); // Hide other field when a predefined reason is selected
    }
  };

  const handleRemoveReason = () => {
    setSelectedReason(null);
    setShowOtherField(false);
    setOtherReason("");
  };

  const handleOtherReason = () => {
    setShowOtherField(true);
    setSelectedReason("Other");
  };

  const handleSave = () => {
    const finalReason = showOtherField ? otherReason : selectedReason;
    svaereason(finalReason, data?.requestOffer?.id);
  };

  const [amountToBeDisbursed, setAmountToBeDisbursed] = useState(
    data?.requestOffer?.netAmount ?? 0
  );
  const [auth, setAuth] = useAuth();
  const inputRef = useRef(null);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 3,
    clientCode: 1501,
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const openpopup = () => {
    setpopupbtnopen(true);
  };

  const closepopup = () => {
    setpopupbtnopen(false);
  };
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday =
    yyyy +
    "-" +
    mm +
    "-" +
    dd +
    "T" +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    "Z";

  const [marginPtg, setMarginPtg] = useState(
    data?.requestOffer?.marginPtg || 0
  );
  const [marginValue, setMarginValue] = useState(
    data?.requestOffer?.marginValue || 0
  );
  const [amountAftMargin, setAmountAftMargin] = useState(
    data?.requestOffer?.amountAftMargin || 0
  );

  const [totalTrade, setTotalTrade] = useState(
    data?.requestOffer?.tradeValue || 0
  );

  // const handleIncreaseIntrestVal = () => {
  //   setAdjustedAmountIntrestVal(Math.round(adjustedAmountIntrestVal) + 1);
  // };

  // const handleDecreaseIntrestVal = () => {
  //   setAdjustedAmountIntrestVal(Math.round(adjustedAmountIntrestVal) - 1);
  // };

  //Margin change

  const [adjustBtn, setAdjustBtn] = useState(false);
  const handleAdjustClick = () => {
    seteditamntToBeDisbursed(true);
  };
  const handleAmountChange = (e) => {
    setEditClickAdjust(true);
    setEditClickAdjustMargin(true);
    setEditClickAdjustMarginvalue(true);
    setEditClickAdjustAmountAftMargin(true);
    setEditClickAdjustIntrestRate(true);
    setEditClickAdjustIntrestValue(true);
    let newVal = e.target.value;
    setAmountToBeDisbursed(newVal);
  };

  console.log("srikar says:", parseFloat(marginValue) + 2);
  const handleMarginChange = (e) => {
    const newMarginPtg = parseFloat(e.target.value);
    setMarginPtg(newMarginPtg);
  };
  const handleMarginValueChange = (e) => {
    const newValue = e.target.value;
    setMarginValue(newValue);
  };
  const handleAmontAfterMarginChange = (e) => {
    const newValue = e.target.value;
    setAmountAftMargin(newValue);
  };
  const handleAnnualIntrestRateChange = (e) => {
    const newValue = e.target.value;
    setInterestPercentage(newValue);
  };
  const handleIntrestValueChange = (e) => {
    const newValue = e.target.value;
    setInterestValue(newValue);
  };
  const isInvalidRange = marginPtg >= 15;
  const isTooLow = interestPercentage < 1;
  const isTooHigh = interestPercentage > 20;
  const isInvalidRangee = interestPercentage >= 15 && interestPercentage <= 20;

  const svaereason = async (finalReason, id) => {
    closepopup();
    setIsLoading(true);
    setIsLoading(true);
    try {
      const constructObj = contextforrejectingOffer(
        authContextObj,
        id,
        finalReason
      );
      const placedOfferResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "reject-request-offer"
      );
      if (placedOfferResponse.res.status === 200) {
        setIsLoading(false);
        setOpenSnackbar(true);
        setApiResultMessage("Rejected offer successfully !");
        setAlertSeverity("success");
        window.location.reload();
      } else {
        setIsLoading(false);
        setOpenSnackbar(true);
        setAlertSeverity("error");
        setApiResultMessage("An error occurred while placing offer");
      }
    } catch (err) {
      setIsLoading(false);
      setOpenSnackbar(true);
      setAlertSeverity("error");
      setApiResultMessage("An error occurred while placing offer");
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  };
  //merge
  const submitOffer = async () => {
    const placedOfferObj = {
      reqOffUlidId: data?.requestOffer?.reqOffUlidId,
      requestOfferRefNo: data?.requestOffer?.reqOfferRefNo,
      value: data?.requestOffer?.offerValue,
      reqAmount: data?.requestOffer?.requestAmt,
      disbursalAmount: amountToBeDisbursed,
      marginPtg: marginPtg,
      marginValue: marginValue,
      amountAftMargin: amountAftMargin,
      interestPtg: parseFloat(interestPercentage),
      term: data?.requestOffer?.term,
      financepartner: data?.requestOffer?.financepartner,
      interestValue: parseFloat(interestValue),
      netAmount: parseInt(amountToBeDisbursed),
      status: "OFPD",
      offerDate: formattedToday,
      placedOfferRefNo: data?.requestOffer?.requestOfferRefNo,
      placedOfferDate: formattedToday,
      anchorTrader: data?.requestOffer?.anchorTraderName,
      tradePartner: data?.requestOffer?.tradePartnerName,
    };

    const requestOfferObj = {
      id: data?.requestOffer?.id,
      reqOffUlidId: data?.requestOffer?.reqOffUlidId,
      reqOfferRefNo: data?.requestOffer?.reqOfferRefNo,
      offerValue: data?.requestOffer?.offerValue,
      requestAmt: data?.requestOffer?.requestAmt,
      tradeValue: totalTrade,
      marginPtg: marginPtg,
      marginValue: marginValue,
      amountAftMargin: amountAftMargin,
      interestPtg: interestPercentage,
      term: data?.requestOffer?.term,
      interestValue: interestValue,
      netAmount: amountToBeDisbursed,
      disbursalAmount: data?.requestOffer?.disbursalAmount,
      status: "ROPD",
      anchorTraderName: data?.requestOffer?.anchorTraderName,
      tradePartnerName: data?.requestOffer?.tradePartnerName,
      anchorTraderGst: data?.requestOffer?.anchorTraderGst,
      tradePartnerGst: data?.requestOffer?.tradePartnerGst,
      anchorTraderGSTComplianceScore:
        data?.requestOffer?.anchorTraderGSTComplianceScore,
      anchorTraderGSTERPPeerScore:
        data?.requestOffer?.anchorTraderGSTERPPeerScore,
      financerequest: data?.requestOffer?.financerequest,
      financepartner: data?.requestOffer?.financepartner,
      placedOffer: placedOfferObj,
      financeRequestDate: data?.requestOffer?.financeRequestDate,
      sellerTradePerformanceIndex:
        data?.requestOffer?.sellerTradePerformanceIndex,
      atRepaymentAmount: data?.requestOffer?.atRepaymentAmount,
      atSettlement: data?.requestOffer?.atSettlement,
      atTransactionCharges: data?.requestOffer?.atTransactionCharges,
      atTransactionChargesInPercentage:
        data?.requestOffer?.atTransactionChargesInPercentage,
      creFees: data?.requestOffer?.creFees,
      effectiveInterestRate: data?.requestOffer?.effectiveInterestRate,
      fpRepaymentAmount: data?.requestOffer?.fpRepaymentAmount,
      fpSettlement: data?.requestOffer?.fpSettlement,
      fpTransactionCharges: data?.requestOffer?.fpTransactionCharges,
      fpTransactionChargesInPercentage:
        data?.requestOffer?.fpTransactionChargesInPercentage,
      netAmountAfterCharges: data?.requestOffer?.netAmountAfterCharges,
      tpSettlement: data?.requestOffer?.tpSettlement,
    };

    setIsLoading(true);
    try {
      const constructObj = contextObjForPlacingOffer(
        authContextObj,
        requestOfferObj,
        placedOfferObj
      );
      const placedOfferResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "on-place-offer"
      );
      if (placedOfferResponse.res.status === 200) {
        setIsLoading(false);
        setOpenSnackbar(true);
        setApiResultMessage("Placed offer successfully !");
        setAlertSeverity("success");
        navigate("/creditbazaar/placed-offers");
      } else {
        setIsLoading(false);
        setOpenSnackbar(true);
        setAlertSeverity("error");
        setApiResultMessage("An error occurred while placing offer");
      }
    } catch (err) {
      setIsLoading(false);
      setOpenSnackbar(true);
      setAlertSeverity("error");
      setApiResultMessage("An error occurred while placing offer");
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      {!creditScoreReportCheck && (
        <div>
          <div
            style={{
              marginTop: "2%",
              marginRight: "5%",
            }}
          >
            <button
              style={{
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 10px",
                cursor: "pointer",
                borderRadius: "5px",
                height: "fit-content",
                fontSize: "12px",
              }}
              onClick={() => setCreditScoreReportCheck(true)}
            >
              Credit Recommendation Report
            </button>
          </div>
          <RequestInformation
            className="body-text"
            style={{ display: "flex", marginTop: "1%" }}
          >
            <div className="main-content">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                  Request Details
                </h3>
              </div>
              <div className="record-details">
                <table>
                  <tbody>
                    <tr>
                      <th>Action By Date</th>
                      <td>
                        {actionDateFunction(
                          new Date(data?.requestOffer?.financeRequestDate),
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Credit Score</th>
                      <td>
                        <span
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          {data?.cbcreProcess?.cumilativeTradeScore}/100
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Anchor Trader</th>
                      <td>{data?.requestOffer?.anchorTraderName}</td>
                    </tr>
                    <tr>
                      <th>Trade Partner</th>
                      <td>{data?.requestOffer?.tradePartnerName}</td>
                    </tr>
                    <tr>
                      <th>Total trade value (INR)</th>
                      <td>
                        {/* <input
                          type="number"
                          name="trade"
                          min="1"
                          onKeyPress={preventMinus}
                          ref={inputRef}
                          onChange={handleTradeChange}
                          value={totalTrade}
                        /> */}
                        <NumericFormat
                          displayType="text"
                          value={totalTrade}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Requested Tenure (Days)</th>
                      <td>{data?.requestOffer?.term}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="main-content" style={{ marginLeft: "5%" }}>
              <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                Final Offer Details
              </h3>
              <div className="record-details">
                <table>
                  <tbody>
                    <tr>
                      <th> Financing Margin (%)</th>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          {editClickAdjustMargin === true ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  name="margin"
                                  value={marginPtg}
                                  min="1"
                                  onKeyPress={preventMinus}
                                  ref={inputRef}
                                  onChange={handleMarginChange}
                                  style={{
                                    outline: "none",
                                    borderColor: "red",
                                    marginRight: "2px",
                                    padding: "5px",
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    setEditClickAdjustMargin(false);
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    backgroundColor: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <span style={{ marginRight: "5px" }}>✔</span>{" "}
                                  Confirm
                                </button>
                              </div>
                              <p
                                style={{
                                  color: "red",
                                  margin: "0px",
                                  fontSize: "10px",
                                }}
                              >
                                Please adjust the Financing Margin according to
                                your calculation and confirm.
                              </p>
                            </>
                          ) : (
                            <>
                              <NumericFormat
                                displayType="text"
                                value={marginPtg}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Margin value (INR)</th>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          {editClickAdjustMarginvalue === true ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  name="margin"
                                  value={marginValue}
                                  min="1"
                                  onKeyPress={preventMinus}
                                  ref={inputRef}
                                  onChange={handleMarginValueChange}
                                  style={{
                                    outline: "none",
                                    borderColor: "red",
                                    marginRight: "2px", // Adds space between input and button
                                    padding: "5px",
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    setEditClickAdjustMarginvalue(false);
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    backgroundColor: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <span style={{ marginRight: "5px" }}>✔</span>{" "}
                                  Confirm
                                </button>
                              </div>

                              {/* Error message in red color */}
                              <p
                                style={{
                                  color: "red",
                                  margin: "0px",
                                  fontSize: "10px",
                                }}
                              >
                                Please adjust the margin value according to your
                                calculation and confirm.
                              </p>
                            </>
                          ) : (
                            <>
                              <NumericFormat
                                displayType="text"
                                value={marginValue}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Amount after margin (INR)</th>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          {editClickAdjustAmountAftMargin === true ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  name="margin"
                                  value={amountAftMargin}
                                  min="1"
                                  onKeyPress={preventMinus}
                                  ref={inputRef}
                                  onChange={handleAmontAfterMarginChange}
                                  style={{
                                    outline: "none",
                                    borderColor: "red",
                                    marginRight: "2px",
                                    padding: "5px",
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    setEditClickAdjustAmountAftMargin(false);
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    backgroundColor: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <span style={{ marginRight: "5px" }}>✔</span>{" "}
                                  Confirm
                                </button>
                              </div>
                              <p
                                style={{
                                  color: "red",
                                  margin: "0px",
                                  fontSize: "10px",
                                }}
                              >
                                Please adjust the Amount after margin according
                                to your calculation and confirm.
                              </p>
                            </>
                          ) : (
                            <>
                              <NumericFormat
                                displayType="text"
                                value={amountAftMargin}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Annualized Interest rate (%)</th>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          {editClickAdjustIntrestRate === true ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  name="margin"
                                  value={interestPercentage}
                                  min="1"
                                  onKeyPress={preventMinus}
                                  ref={inputRef}
                                  onChange={handleAnnualIntrestRateChange}
                                  style={{
                                    outline: "none",
                                    borderColor: "red",
                                    marginRight: "2px",
                                    padding: "5px",
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    setEditClickAdjustIntrestRate(false);
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    backgroundColor: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <span style={{ marginRight: "5px" }}>✔</span>{" "}
                                  Confirm
                                </button>
                              </div>
                              <p
                                style={{
                                  color: "red",
                                  margin: "0px",
                                  fontSize: "10px",
                                }}
                              >
                                Please adjust the Annualized Interest rate
                                according to your calculation and confirm.
                              </p>
                            </>
                          ) : (
                            <>
                              <NumericFormat
                                displayType="text"
                                value={interestPercentage}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Interest value (INR)</th>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          {editClickAdjustIntrestValue === true ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="number"
                                  name="margin"
                                  value={interestValue}
                                  min="1"
                                  onKeyPress={preventMinus}
                                  ref={inputRef}
                                  onChange={handleIntrestValueChange}
                                  style={{
                                    outline: "none",
                                    borderColor: "red",
                                    marginRight: "2px",
                                    padding: "5px",
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    setEditClickAdjustIntrestValue(false);
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    backgroundColor: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <span style={{ marginRight: "5px" }}>✔</span>{" "}
                                  Confirm
                                </button>
                              </div>
                              <p
                                style={{
                                  color: "red",
                                  margin: "0px",
                                  fontSize: "10px",
                                }}
                              >
                                Please adjust the Interest value according to
                                your calculation and confirm.
                              </p>
                            </>
                          ) : (
                            <>
                              <NumericFormat
                                displayType="text"
                                value={interestValue}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Amount to be disbursed (INR)</th>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {editamntToBeDisbursed === true ? (
                          <>
                            <input
                              type="number"
                              name="margin"
                              value={amountToBeDisbursed}
                              min="1"
                              onKeyPress={preventMinus}
                              ref={inputRef}
                              onChange={handleAmountChange}
                              style={{
                                outline: "none",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <NumericFormat
                              displayType="text"
                              value={amountToBeDisbursed}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                            <button
                              onClick={handleAdjustClick}
                              style={{
                                backgroundColor: "#4A3AFF",
                                color: "white",
                                border: "none",
                                padding: "10px 20px",
                                cursor: "pointer",
                                borderRadius: "5px",
                                height: "fit-content",
                              }}
                            >
                              Adjust
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </RequestInformation>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "30px",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {editClickAdjustMarginvalue === true ||
            editClickAdjustMargin === true ||
            editClickAdjustAmountAftMargin === true ||
            editClickAdjustIntrestRate === true ||
            editClickAdjustIntrestValue === true ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "red",
                }}
              >
                <p>Please Save the changes</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      height: "fit-content",
                      opacity: "0.5",
                    }}
                  >
                    Place an Offer
                  </button>

                  <button
                    style={{
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      height: "fit-content",
                      opacity: "0.5",
                    }}
                  >
                    Reject Offer
                  </button>
                </div>
              </div>
            ) : (
              <>
                <button
                  style={{
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    height: "fit-content",
                    opacity: amountToBeDisbursed > 0 ? "1" : "0.5",
                  }}
                  disabled={amountToBeDisbursed > 0 ? false : true}
                  onClick={submitOffer}
                >
                  Place an Offer
                </button>

                <button
                  style={{
                    backgroundColor: "#4A3AFF",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    height: "fit-content",
                    opacity: amountToBeDisbursed > 0 ? "1" : "0.5",
                  }}
                  disabled={amountToBeDisbursed > 0 ? false : true}
                  onClick={openpopup}
                >
                  Reject Offer
                </button>
              </>
            )}

            <Dialog
              open={popupbtnopen}
              onClose={closepopup}
              maxWidth="sm"
              PaperProps={{
                style: { borderRadius: 20, width: "100%" },
              }}
            >
              <DialogTitle
                style={{
                  backgroundColor: "#ECF1FC",
                  color: "black",
                }}
              >
                Reject the offer
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closepopup}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "black",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                {isLoading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      zIndex: "1100",
                    }}
                  />
                )}
                <DialogContentText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                      gap: "10px",
                      color: "black",
                    }}
                  >
                    <Typography style={{ fontSize: "17px" }}>
                      Tell us more :
                    </Typography>

                    <div className="record-details">
                      {selectedReason && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {selectedReason === "Other" ? (
                            <></>
                          ) : (
                            <p
                              style={{
                                backgroundColor: "white",
                                color: "#8b8e92",
                                transition: "color 0.3s",
                              }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.color = "black")
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.color = "#8b8e92")
                              }
                            >
                              {selectedReason}
                            </p>
                          )}

                          {selectedReason && selectedReason !== "Other" && (
                            <IconButton onClick={handleRemoveReason}>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      )}

                      {!selectedReason && !showOtherField && (
                        <div>
                          <select
                            style={{
                              width: "100%",
                              padding: "15px",
                              borderRadius: "15px",
                              border: "1px solid #babdc0",

                              fontWeight: "bold",
                              backgroundColor: "white",
                              transition: "color 0.3s",
                            }}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              if (selectedValue === "More ...") {
                                handleOtherReason();
                              } else {
                                handleSelectReason(selectedValue);
                              }
                            }}
                          >
                            <option value="" disabled selected>
                              Select a reason
                            </option>
                            {reasons?.map((reason) => (
                              <option key={reason} value={reason}>
                                {reason}
                              </option>
                            ))}
                            <option value="More ...">More ...</option>
                          </select>
                        </div>
                      )}

                      {showOtherField && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginTop: "15px",
                          }}
                        >
                          <TextField
                            label="Other Reason"
                            variant="standard"
                            fullWidth
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                            InputProps={{
                              disableUnderline: false,
                            }}
                            style={{ flex: 1 }}
                          />
                          {selectedReason && (
                            <IconButton onClick={handleRemoveReason}>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      type="button"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "8px 8px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        margin: "5px",
                        width: "100px",
                        position: "absolute",
                        right: 8,
                        bottom: 8,
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        </div>
      )}
      {creditScoreReportCheck && (
        <CreditRecommendationReport
          creditScoreReportCheck={creditScoreReportCheck}
          setCreditScoreReportCheck={setCreditScoreReportCheck}
          financeRequestData={data}
          authToken={authToken}
        />
      )}
      <div style={{ marginTop: "30vh" }}>
        <Footer />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default FinanceRequestIndividual;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }
  .main-content {
    width: 45%;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    width: 55%;
  }
  table input {
    width: 100%;
    padding: 9px 0px 9px 5px;
    font-size: 14px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
