import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Radio,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";

const CustomButton = styled(Button)({
  borderRadius: "5px",
  backgroundColor: "#4A3AFF",
  color: "white",
  border: "none",
  "&:hover": {
    backgroundColor: "#00A6A6",
  },
  borderRadius: "8px",
});

const Page2 = ({ selectedFiles }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);

  const handleReviewFile = async () => {
    // const response = await axios.get(`/api/review-file/${selectedFile.id}`);
    setFileContent();
  };

  const handleCreateTrade = async () => {
    // await axios.post("/api/create-trade", { fileId: selectedFile.id });
    alert("Trade created successfully!");
  };

  return (
    <>
      {" "}
      {
        //<Button onClick={setshowpageTwo(false)}>Back</Button>
      }
      <Container maxWidth="md" sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h4" sx={{ color: "#333", mb: 4 }}>
          Review and Create Trade
        </Typography>
        <Typography variant="h6" sx={{ color: "#333", mb: 4 }}>
          These are the list of trades selected in the previous step , Review
          and create a trade
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <List>
            {selectedFiles?.map((file) => (
              <ListItem key={file.id}>
                <Radio
                  checked={selectedFile === file}
                  onChange={() => setSelectedFile(file)}
                />
                <ListItemText
                  primary={file.name}
                  secondary={`Size: ${file.size} KB, Date: ${file.uploadDate}`}
                />
              </ListItem>
            ))}
          </List>
        </div>

        <CustomButton
          onClick={handleReviewFile}
          variant="contained"
          disabled={!selectedFile}
          sx={{ mt: 2 }}
        >
          Review
        </CustomButton>

        {fileContent && (
          <div
            style={{
              marginTop: "20px",
              padding: "15px",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6">Document Summary</Typography>
            <CustomButton
              onClick={handleCreateTrade}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Create Trade
            </CustomButton>
          </div>
        )}
      </Container>
    </>
  );
};

export default Page2;
