import React from "react";

function Component4({ isctive }) {
  return (
    <div
      className="ThirdComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
          textAlign: "center",
        }}
      >
        Review and select the files from the list below to proceed
      </h3>
      <div
        className="PdfDocHolderComp"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "100%",
          height: "500px",
          border: "1px solid",
          gap: "10px",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        {/* Left Component - PDF Viewer */}
        <div
          className="leftComponent"
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#f9f9f9",
          }}
        >
          {/* Placeholder for PDF Viewer */}
          <div
            style={{
              width: "90%",
              height: "90%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
            }}
          >
            <p style={{ fontSize: "16px", color: "#666" }}>
              PDF Viewer Placeholder
            </p>
          </div>
        </div>

        {/* Right Component - Form */}
        <div
          className="rightComponent"
          style={{
            flex: 1.5,
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #ddd",
            padding: "20px",
            background: "#fff",
          }}
        >
          <h2
            style={{
              fontWeight: "700",
              color: "black",
              marginBottom: "20px",
            }}
          >
            Summary of Your Trade Detail
          </h2>
          <div
            style={{
              maxHeight: "400px",
              overflowY: "scroll",
              padding: "10px 20px",
              borderRadius: "10px",
              border: "1px solid #ddd",
              scrollbarWidth: "thin",
            }}
          >
            <form>
              {[
                "SourceName",
                "TradeStatus",
                "SourceVal",
                "TargetName",
                "TargetVal",
                "TradeAdvance",
                "TradeAmount",
                "TradeDate",
                "TradeDocumnentCrediility",
                "TradeSource",
                "TradeType",
                "TradeNo",
                "CreatedDateTime",
                "HasTradeAdvanceAmount",
                "HsnCode",
                "IsDisbursed",
                "IsManuallyAdded",
                "Irn",
                "IsRepaid",
                "IsSetteled",
                "MetricEInvoiceDoc",
                "metricEInvoiceFlag",
                "metricEWayBillFlag",
                "metricGRNDoc",
                "metricGRNFlag",
                "metricTPConfirmationDoc",
                "metricTPFlag",
                "sacCode",
                "tradeDocumentDescription",
                "tradeMileStone",
                "tradePartnerGSTComplianceScore",
                "tradePartnerLocation",
                "tradeTerms",
              ].map((field, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  <label
                    htmlFor={field}
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  ></label>
                  <input
                    id={field}
                    type="text"
                    placeholder={field}
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "0.1px solid #333",
                      outline: "none",
                      padding: "5px 0",
                    }}
                  />
                </div>
              ))}
            </form>
          </div>
          <button
            style={{
              color: "white",
              fontSize: "18",
              marginTop: "20px",
              marginLeft: "50px",
              paddingTop: "12px",
              paddingBottom: "12px",
              fontWeight: "500",
              border: "none",
              borderRadius: "4px",
              width: "130px",
              backgroundColor: "#297FFF",

              height: "50px",
              opacity: isctive ? "1" : "0.5",
            }}
          >
            Create Trade{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Component4;
