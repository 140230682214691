import React from "react";

function Component1({ setShowTable }) {
  return (
    <div
      className="firstComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
        }}
      >
        Pick your Anchor Trader and choose your trade path.
      </h3>

      {/* Dropdown for Anchor Trader */}
      <div
        className="content1"
        style={{
          display: "flex",
          gap: "50px",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#333333",
            fontSize: "16",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {" "}
          Select Anchor Trader:
        </p>
        <select
          style={{ width: "250px", height: "35px" }}
          onChange={(e) =>
            console.log("Selected Anchor Trader:", e.target.value)
          }
        >
          <option value="">Select...</option>
          <option value="trader1">Trader 1</option>
          <option value="trader2">Trader 2</option>
          <option value="trader3">Trader 3</option>
        </select>
      </div>

      {/* Dropdown for S3 Path */}
      <div
        className="content2"
        style={{
          display: "flex",
          gap: "95px",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#333333",
            fontSize: "16",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          Select S3 Path:
        </p>
        <select
          style={{ width: "250px", height: "35px" }}
          onChange={(e) => console.log("Selected S3 Path:", e.target.value)}
        >
          <option value="">Select...</option>
          <option value="path1">Path 1</option>
          <option value="path2">Path 2</option>
          <option value="path3">Path 3</option>
        </select>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          marginTop: "100px",
        }}
      >
        <button
          style={{
            color: "white",
            fontSize: "18",
            fontWeight: "500",
            backgroundColor: "#297FFF",
            border: "none",
            borderRadius: "4px",
            width: "130px",
            height: "50px",
          }}
          onClick={() => {
            setShowTable(true);
          }}
        >
          Search
        </button>
      </div>
    </div>
  );
}

export default Component1;
