import React, { useCallback, useEffect, useState } from "react";
import { getplacedoffers } from "../API/FP/Actions";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { controllerAPI, callAPI } from "../API/FP/Actions";
import {
  contextObjController,
  dateFormat,
  requestStates,
} from "../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import {
  contextObjforatplaced,
  contextObjforapicall,
  contextObj,
} from "../Common/Constants";
import Pagenotfound from "../Common/pagenotfound";

function DashboardAt() {
  const [auth, setAuth] = useAuth();
  const [dashUrl, setDashUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );

  useEffect(() => {
    //fetchData();
    fetchToken();
  }, []);
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  console.log(
    "dashUrl is :",
    dashUrl?.res?.data?.data?.anchorTrader?.dashLink1
  );
  const fetchToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const constructObj = contextObjController(authContextObj, anchorTraderID);
      const profileDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "get-at-profile-details"
      );

      if (profileDetailsResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }
      if (profileDetailsResponse.res.status === 200) {
        setDashUrl(
          profileDetailsResponse?.res?.data?.data?.anchorTrader?.dashLink1
        );
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, []);
  const fetchData = async () => {
    // setLoading(true);
    // try {
    //   setLoading(true);
    //   const constructObj = contextObjforapicall(authContextObj, anchorTraderID);
    //   // Updated API call with pagination parameters
    //   const ATTradesresponse = await controllerAPI(
    //     "post",
    //     auth?.token,
    //     constructObj,
    //     `get-all-trades?page=${currentPage - 1}&size=${pageSize}&sort=id,desc`
    //   );
    //   if (ATTradesresponse?.response?.status === 401) {
    //     setAuth({
    //       ...auth,
    //       user: null,
    //       token: "",
    //       userDisplayName: "",
    //       userDetails: null,
    //     });
    //     localStorage.removeItem("auth");
    //     navigate("/creditbazaar/");
    //   }
    //   if (ATTradesresponse.res.status === 200) {
    //     setData(ATTradesresponse.res.data.data);
    //     setTotalPages(
    //       Math.ceil(ATTradesresponse?.res?.data?.totalElements / pageSize)
    //     );
    //   }
    // } catch (err) {
    //   console.log("Error fetching data:", err);
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <>
      {isLoading ? (
        <div
          className="loading-container"
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            zIndex: "1",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <CircularProgress
            style={{ position: "absolute", left: "50%", top: "50%" }}
          />
        </div>
      ) : (
        <>
          {" "}
          <h2 style={{ marginTop: "50px" }}>DashBoard Reports</h2>
          <div
            style={{
              height: "calc(100vh - 50px)",
              width: "100%",
              overflow: "hidden",
              margin: "0px 50px 0px 0px",
              border: "1px solid",
            }}
          >
            <iframe
              //      src="http://127.0.0.1:5000/superset/dashboard/p/wWj9Vj6VpM8/" // Replace with the actual Paisabuddy dashboard URL
              // dev superset      src="http://a3a09cf6c794c410094f5c29c7756ebe-1110877928.ap-south-1.elb.amazonaws.com:8088/superset/dashboard/p/p3GvBBmvrYe/"
              // prod superset
              src={dashUrl}
              title="Paisabuddy Dashboard"
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                display: "block",
                overflow: "auto",
                margin: "0",
                padding: "30px",
              }}
              scrolling="yes"
              allowFullScreen
            />
          </div>
        </>
      )}
    </>
  );
}
export default DashboardAt;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
