import React from "react";

function Component2({ handleCheckboxChange, files, selectedFilesName }) {
  return (
    <div
      className="secondComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
        }}
      >
        Review and select the files from the list below to proceed
      </h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          margin: "20px 0",
          fontSize: "18px",
          textAlign: "left",
          border: "2px solid #ddd",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filename
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filesize
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Upload Date
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Select
            </th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index}>
              <td style={{ padding: "10px" }}>{file.filename}</td>
              <td>{file.filesize}</td>
              <td>{file.uploadDate}</td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedFilesName.some(
                    (f) => f.filename === file.filename
                  )}
                  onChange={() => handleCheckboxChange(file)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          marginTop: "100px",
        }}
      ></div>
    </div>
  );
}

export default Component2;
