import React from "react";

function Component3({ selectedFilesName, setReviewClicked }) {
  return (
    <div
      className="ThirdComponent"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "50px 50px 0px",
      }}
    >
      <h3
        style={{
          color: "black",
          fontSize: "18px",
          fontWeight: "600",
          lineHeight: "2",
          wordWrap: "break-word",
        }}
      >
        Review and select the files from the list below to proceed
      </h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          margin: "20px 0",
          fontSize: "18px",
          textAlign: "left",
          border: "2px solid #ddd", // Overall table border
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filename
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Filesize
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Upload Date
            </th>
            <th
              style={{
                padding: "8px",
                background: "rgba(0, 123, 255, 0.10)",
                color: "#615E83",
              }}
            >
              Table Head
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedFilesName.map((file, index) => (
            <tr key={index}>
              <td style={{ padding: "15px" }}>{file.filename}</td>
              <td style={{ padding: "15px" }}>{file.filesize}</td>
              <td style={{ padding: "15px" }}>{file.uploadDate}</td>
              <td style={{ padding: "15px" }}>
                <button
                  type="button"
                  style={{
                    background: "#28A745",
                    borderRadius: "2px",
                    color: "white",
                    border: "0.5px #00000099 solid",
                    width: "80px",
                    height: "30px",
                  }}
                  onClick={() => {
                    setReviewClicked(true);
                  }}
                >
                  Review{" "}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          marginTop: "100px",
        }}
      ></div>
    </div>
  );
}

export default Component3;
