import React, { useState } from "react";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  Divider,
  Step,
  StepLabel,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Page2 from "./page2";
import CloseIcon from "@mui/icons-material/Close";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Component1 from "./Component1";
import Component4 from "./Component4";
import Component3 from "./Component3";
import Component2 from "./Component2";

const Page1 = ({ setSelectedFiles }) => {
  const demoAnchorTraders = [
    { id: 1, name: "Prime textiles" },
    { id: 2, name: "V1 Facilities" },
    { id: 3, name: "Appolo Hospitals" },
  ];

  const steps = [
    "Select Anchor Trader & Pathway",
    "Document Review & Trade Setup",
  ];
  const files = [
    { filename: "file1.txt", filesize: "15 KB", uploadDate: "2024-11-01" },
    { filename: "file2.pdf", filesize: "45 KB", uploadDate: "2024-11-02" },
    { filename: "file3.jpg", filesize: "120 KB", uploadDate: "2024-11-03" },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [anchorTraders] = useState(demoAnchorTraders);
  const [selectedAnchorTrader, setSelectedAnchorTrader] = useState("");
  const [s3Paths, setS3Paths] = useState([]);
  const [selectedS3Path, setSelectedS3Path] = useState("");
  const [nextClicked, setNextClicked] = useState(false);
  //const [files, setFiles] = useState([]);
  const [selectedFilesName, setSelectedFilesName] = useState([]);
  const [showNextWizard, setShowNextWizard] = useState(false);
  const [selectedFiles, setSelectedFilesState] = useState([]);
  const [showpageTwo, setshowpageTwo] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [isctive, setisActive] = useState(false);
  const [reviewClicked, setReviewClicked] = useState(false);
  const navigate = useNavigate();
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (file) => {
    setSelectedFilesName((prevSelectedFiles) => {
      // Determine if the file is already selected
      const updatedSelectedFiles = prevSelectedFiles.find(
        (f) => f.filename === file.filename
      )
        ? prevSelectedFiles.filter((f) => f.filename !== file.filename)
        : [...prevSelectedFiles, file];

      // Update `isActive` if there is at least one file selected
      setisActive(updatedSelectedFiles.length > 0);

      return updatedSelectedFiles;
    });
  };

  const openpopup = () => {
    setpopupbtnopen(true);
  };
  const closepopup = () => {
    setpopupbtnopen(false);
    window.location.reload();
  };

  // Function to go to the next step
  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, steps.length - 1)
    );
    setNextClicked(true);
  };

  // Function to go to the previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    setNextClicked(false);
  };

  return (
    <>
      {showpageTwo === true ? (
        <Page2 selectedFiles={selectedFiles} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "100px",
          }}
        >
          <p>Want to upload trade documents and read them? Click below</p>
          <button
            style={{
              borderRadius: "10px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              cursor: "pointer",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
              margin: "5px",
              padding: "15px 30px",
              fontSize: "15px",
            }}
            onClick={openpopup}
          >
            Trade document wizard
          </button>
          <Dialog
            open={popupbtnopen}
            onClose={closepopup}
            fullWidth
            maxWidth="xl"
            PaperProps={{
              style: {
                borderRadius: "10px",
                width: "60%",
                height: "75vh",
                maxHeight: "90vh",
              },
            }}
          >
            <DialogTitle> </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={closepopup}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              {isLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    zIndex: "1100",
                  }}
                />
              )}
              <DialogContentText>
                <div
                  className="totalComponent"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          style={{
                            opacity: activeStep === index ? "1" : "0.5", // Full opacity for the active step
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {nextClicked ? (
                    // 4th component
                    <>
                      {reviewClicked ? (
                        <>
                          <Component4 isctive={isctive} />
                        </>
                      ) : (
                        // 3rd component
                        <>
                          {" "}
                          <Component3
                            setReviewClicked={setReviewClicked}
                            selectedFilesName={selectedFilesName}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {showTable == true ? (
                        //Second component
                        <Component2
                          handleCheckboxChange={handleCheckboxChange}
                          files={files}
                          selectedFilesName={selectedFilesName}
                        />
                      ) : (
                        // First component

                        <Component1 setShowTable={setShowTable} />
                      )}
                    </>
                  )}

                  {/* Navigation Buttons */}

                  {!reviewClicked ? (
                    <>
                      {nextClicked ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "20px",
                            }}
                          >
                            <button
                              style={{
                                color: "black",
                                fontSize: "18",
                                fontWeight: "500",
                                border: "1px solid black",
                                borderRadius: "4px",
                                width: "130px",
                                backgroundColor: isctive ? "white" : "grey",
                                height: "50px",
                                opacity: isctive ? "1" : "0.5",
                              }}
                              onClick={handleBack}
                              disabled={!isctive}
                            >
                              Back
                            </button>
                            <p></p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "20px",
                            }}
                          >
                            <p></p>
                            <button
                              style={{
                                color: "white",
                                fontSize: "18",
                                fontWeight: "500",
                                border: "none",
                                borderRadius: "4px",
                                width: "130px",
                                backgroundColor: isctive ? "#297FFF" : "grey",
                                height: "50px",
                                opacity: isctive ? "1" : "0.5",
                              }}
                              onClick={handleNext}
                              disabled={!isctive}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Page1;
